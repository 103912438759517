import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from 'axios'
import { useToast } from 'vuestic-ui'
import { useRoute } from 'vue-router'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loggedIn: !!localStorage.getItem('token'),
    user: null,
    meta: {},
  }),

  getters: {},

  actions: {
    async login(credentials: any) {
      await axios
        .get('login', {
          params: credentials,
        })
        .then((response) => {
          const { data } = response
          const token = data.token
          const router = useRoute()
          localStorage.setItem('token', token)
          axios.defaults.headers.common['X-API-KEY'] = token
          window.location.replace('/dashboard')
        })
        .catch((response) => {
          const { init } = useToast()
          init({ message: 'Неправильная почта или пароль', color: 'danger' })
        })
    },
    logout() {
      localStorage.setItem('token', '')
      axios.defaults.headers.common['X-API-KEY'] = ''
      window.location.replace('/login')
    },
  },
})
