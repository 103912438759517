import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useAuthStore } from '../stores/auth.store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    meta: {
      requireAuth: true,
    },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'referrals',
        path: 'referrals',
        component: () => import('../pages/referrals/ReferralsPage.vue'),
      },
      {
        name: 'payments',
        path: 'payments',
        component: () => import('../pages/payments/PaymentsPage.vue'),
      },
      {
        name: 'deals',
        path: 'deals',
        component: () => import('../pages/deals/DealsPage.vue'),
      },
      {
        name: 'admins',
        path: 'admins',
        component: () => import('../pages/admins/AdminsPage.vue'),
      },
      {
        name: 'dds',
        path: 'dds',
        component: () => import('../pages/dds/DdsPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !useAuthStore().loggedIn) {
    next({ name: 'login' })
  } else if (!to.meta.requireAuth && useAuthStore().loggedIn) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

export default router
