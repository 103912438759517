<template>
  <svg :height="height" viewBox="0 0 517 250" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_115_3)">
      <path
        d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z"
        fill="url(#paint0_linear_115_3)"
      />
      <path d="M250 218.75L229.678 193.346L222.965 184.955L204.42 161.775L175 125H250V218.75Z" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125 250C55.9644 250 0 194.035 0 125C0 55.9644 55.9644 0 125 0C194.035 0 250 55.9644 250 125C250 150.225 242.529 173.704 229.678 193.346L222.965 184.955L204.42 161.775L175 125H212.5C212.5 76.6751 173.325 37.5 125 37.5C66.3198 37.5 18.75 85.0698 18.75 143.75C18.75 202.43 66.3198 250 125 250Z"
        fill="white"
      />
      <path
        d="M98.1645 127.045C105.597 127.045 111.622 120.991 111.622 113.523C111.622 106.054 105.597 100 98.1645 100C90.7322 100 84.707 106.054 84.707 113.523C84.707 120.991 90.7322 127.045 98.1645 127.045Z"
        fill="white"
      />
      <path
        d="M151.992 181.134C159.425 181.134 165.45 175.079 165.45 167.611C165.45 160.143 159.425 154.088 151.992 154.088C144.56 154.088 138.535 160.143 138.535 167.611C138.535 175.079 144.56 181.134 151.992 181.134Z"
        fill="white"
      />
      <path d="M152.952 100L83.75 181.136H97.2074L166.41 100H152.952Z" fill="white" />
    </g>
    <path
      d="M320 44.4H334.64V53.76C335.2 52.48 336 51.2 337.04 49.92C338.08 48.56 339.32 47.4 340.76 46.44C342.2 45.4 343.76 44.56 345.44 43.92C347.2 43.28 349.12 42.96 351.2 42.96C352.72 42.96 354 43.04 355.04 43.2C356.16 43.36 356.88 43.52 357.2 43.68V57.72C356.4 57.4 355.36 57.12 354.08 56.88C352.88 56.56 351.24 56.4 349.16 56.4C346.76 56.4 344.72 56.8 343.04 57.6C341.36 58.4 339.96 59.48 338.84 60.84C337.8 62.12 337.04 63.64 336.56 65.4C336.08 67.16 335.84 69 335.84 70.92V108H320V44.4Z"
      fill="url(#paint1_linear_115_3)"
    />
    <path
      d="M394.386 109.44C390.066 109.44 385.946 108.8 382.026 107.52C378.186 106.16 374.826 104.16 371.946 101.52C369.066 98.8 366.786 95.4 365.106 91.32C363.426 87.16 362.586 82.32 362.586 76.8V74.4C362.586 69.12 363.386 64.52 364.986 60.6C366.586 56.68 368.786 53.44 371.586 50.88C374.386 48.24 377.626 46.28 381.306 45C384.986 43.64 388.946 42.96 393.186 42.96C403.186 42.96 410.786 45.6 415.986 50.88C421.186 56.16 423.786 63.8 423.786 73.8V80.52H378.786C378.866 83.32 379.346 85.76 380.226 87.84C381.106 89.84 382.266 91.48 383.706 92.76C385.146 94.04 386.786 95 388.626 95.64C390.546 96.2 392.466 96.48 394.386 96.48C398.466 96.48 401.546 95.88 403.626 94.68C405.706 93.48 407.186 91.72 408.066 89.4H422.586C421.306 96.04 418.146 101.04 413.106 104.4C408.066 107.76 401.826 109.44 394.386 109.44ZM393.786 55.92C391.946 55.92 390.106 56.16 388.266 56.64C386.506 57.12 384.946 57.88 383.586 58.92C382.226 59.96 381.106 61.36 380.226 63.12C379.346 64.88 378.866 67 378.786 69.48H408.426C408.346 66.84 407.906 64.64 407.106 62.88C406.386 61.12 405.346 59.72 403.986 58.68C402.706 57.64 401.186 56.92 399.426 56.52C397.666 56.12 395.786 55.92 393.786 55.92Z"
      fill="url(#paint2_linear_115_3)"
    />
    <path
      d="M446.595 109.44C443.795 109.44 441.395 108.44 439.395 106.44C437.395 104.44 436.395 102.04 436.395 99.24C436.395 96.28 437.395 93.84 439.395 91.92C441.395 90 443.795 89.04 446.595 89.04C449.395 89.04 451.795 90 453.795 91.92C455.795 93.84 456.795 96.28 456.795 99.24C456.795 102.04 455.795 104.44 453.795 106.44C451.795 108.44 449.395 109.44 446.595 109.44ZM446.595 63.36C443.795 63.36 441.395 62.36 439.395 60.36C437.395 58.36 436.395 55.96 436.395 53.16C436.395 50.2 437.395 47.76 439.395 45.84C441.395 43.92 443.795 42.96 446.595 42.96C449.395 42.96 451.795 43.92 453.795 45.84C455.795 47.76 456.795 50.2 456.795 53.16C456.795 55.96 455.795 58.36 453.795 60.36C451.795 62.36 449.395 63.36 446.595 63.36Z"
      fill="url(#paint3_linear_115_3)"
    />
    <path
      d="M486.791 234.44C482.471 234.44 478.351 233.8 474.431 232.52C470.591 231.16 467.231 229.16 464.351 226.52C461.471 223.8 459.191 220.4 457.511 216.32C455.831 212.16 454.991 207.32 454.991 201.8V199.4C454.991 194.12 455.791 189.52 457.391 185.6C458.991 181.68 461.191 178.44 463.991 175.88C466.791 173.24 470.031 171.28 473.711 170C477.391 168.64 481.351 167.96 485.591 167.96C495.591 167.96 503.191 170.6 508.391 175.88C513.591 181.16 516.191 188.8 516.191 198.8V205.52H471.191C471.271 208.32 471.751 210.76 472.631 212.84C473.511 214.84 474.671 216.48 476.111 217.76C477.551 219.04 479.191 220 481.031 220.64C482.951 221.2 484.871 221.48 486.791 221.48C490.871 221.48 493.951 220.88 496.031 219.68C498.111 218.48 499.591 216.72 500.471 214.4H514.991C513.711 221.04 510.551 226.04 505.511 229.4C500.471 232.76 494.231 234.44 486.791 234.44ZM486.191 180.92C484.351 180.92 482.511 181.16 480.671 181.64C478.911 182.12 477.351 182.88 475.991 183.92C474.631 184.96 473.511 186.36 472.631 188.12C471.751 189.88 471.271 192 471.191 194.48H500.831C500.751 191.84 500.311 189.64 499.511 187.88C498.791 186.12 497.751 184.72 496.391 183.68C495.111 182.64 493.591 181.92 491.831 181.52C490.071 181.12 488.191 180.92 486.191 180.92Z"
      fill="white"
    />
    <path
      d="M417.181 234.44C412.861 234.44 408.741 233.8 404.821 232.52C400.981 231.16 397.621 229.16 394.741 226.52C391.861 223.8 389.581 220.4 387.901 216.32C386.221 212.16 385.381 207.32 385.381 201.8V199.4C385.381 194.12 386.181 189.52 387.781 185.6C389.381 181.68 391.581 178.44 394.381 175.88C397.181 173.24 400.421 171.28 404.101 170C407.781 168.64 411.741 167.96 415.981 167.96C425.981 167.96 433.581 170.6 438.781 175.88C443.981 181.16 446.581 188.8 446.581 198.8V205.52H401.581C401.661 208.32 402.141 210.76 403.021 212.84C403.901 214.84 405.061 216.48 406.501 217.76C407.941 219.04 409.581 220 411.421 220.64C413.341 221.2 415.261 221.48 417.181 221.48C421.261 221.48 424.341 220.88 426.421 219.68C428.501 218.48 429.981 216.72 430.861 214.4H445.381C444.101 221.04 440.941 226.04 435.901 229.4C430.861 232.76 424.621 234.44 417.181 234.44ZM416.581 180.92C414.741 180.92 412.901 181.16 411.061 181.64C409.301 182.12 407.741 182.88 406.381 183.92C405.021 184.96 403.901 186.36 403.021 188.12C402.141 189.88 401.661 192 401.581 194.48H431.221C431.141 191.84 430.701 189.64 429.901 187.88C429.181 186.12 428.141 184.72 426.781 183.68C425.501 182.64 423.981 181.92 422.221 181.52C420.461 181.12 418.581 180.92 416.581 180.92Z"
      fill="white"
    />
    <path d="M320 149H377.6V163.88H338V185H374V199.4H338V233H320V149Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_115_3" x1="250" y1="187.5" x2="0" y2="187.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D00F1B" />
        <stop offset="1" stop-color="#F51F5F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_115_3"
        x1="456.795"
        y1="92.82"
        x2="320"
        y2="92.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D00F1B" />
        <stop offset="1" stop-color="#F51F5F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_115_3"
        x1="456.795"
        y1="92.82"
        x2="320"
        y2="92.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D00F1B" />
        <stop offset="1" stop-color="#F51F5F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_115_3"
        x1="456.795"
        y1="92.82"
        x2="320"
        y2="92.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D00F1B" />
        <stop offset="1" stop-color="#F51F5F" />
      </linearGradient>
      <clipPath id="clip0_115_3">
        <rect width="250" height="250" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
