import './scss/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import stores from './stores'
import router from './router'
import axios from './plugins/axios'
import VueAxios from 'vue-axios'

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(VueAxios, axios)
app.use(
  createVuestic({
    config: {
      colors: {
        presets: {
          light: {
            primary: '#000000',
            secondary: '#666E75',
            success: '#3D9209',
            info: '#158DE3',
            danger: '#E42222',
            warning: '#FFD43A',
            backgroundPrimary: '#FFFFFF',
            backgroundSecondary: '#FFFFFF',
            backgroundElement: '#ECF0F1',
            backgroundBorder: '#DEE5F2',
            textPrimary: '#262824',
            textInverted: '#FFFFFF',
            shadow: 'rgba(0, 0, 0, 0.12)',
            focus: '#49A8FF',
            transparent: 'rgba(0, 0, 0, 0)',
            backgroundLanding: '#f4f9fc',
            backgroundLandingBorder: 'rgba(155, 179, 206, 0.8)',
            white: '#FFFFFF',
          },
        },
      },
    },
  }),
)

app.mount('#app')
