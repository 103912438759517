export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Рабочий стол',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'users',
      displayName: 'Пользователи',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'referrals',
      displayName: 'Реферальные начисления',
      meta: {
        icon: 'group_add',
      },
    },
    {
      name: 'payments',
      displayName: 'Платежи',
      meta: {
        icon: 'payments',
      },
    },
    {
      name: 'deals',
      displayName: 'Сделки',
      meta: {
        icon: 'handshake',
      },
    },
    {
      name: 'admins',
      displayName: 'Администраторы',
      meta: {
        icon: 'admin_panel_settings',
      },
    },
    {
      name: 'dds',
      displayName: 'DDS',
      meta: {
        icon: 'attach_money',
      },
    },
  ] as INavigationRoute[],
}
