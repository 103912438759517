import axios from 'axios'

axios.defaults.baseURL = import.meta.env.VITE_API_URI
axios.defaults.headers.common['X-API-KEY'] = localStorage.getItem('token')
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://refee-test.axiomica.io/'
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true
axios.defaults.withCredentials = false

export default axios
